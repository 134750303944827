import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query ContactUsPageQuery {
    file(name: {eq: "contact-us-boy-in-pile-of-leaves"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const ContactUs = ({data}) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return(
    <Layout>
      <SEOComponent
        title="Contact Us"
        description="Contact Strong Foundations CT"
      />
      <Hero image={banner} header="Contact"/>
      <section>
        <div className="container my-4 mx-auto pt-0 px-4 pb-6">
          <div className="grid sm:grid-cols-12">
            <div className="font-serif sm:col-span-5 m-4">
              <h2 className="text-2xl">Contact Us Today!</h2>
              <p className="text-lg text-gray-600 mb-4">Please contact us with any questions or to begin services!</p>
              <p className="mb-4"><strong><em>Phone: (860)870-5997</em></strong></p>
              <p className="mb-4"><strong><em>Fax: (860) 870-8170</em></strong></p>
              <p className="mb-4"><strong><em>Monday – Friday: 9AM – 4PM</em></strong></p>
              <h2 className="text-2xl">Location</h2>
              <p className="mb-4">550 Hartford Turnpike<br/>Vernon, CT 06066<br/>(860) 870-5997</p>
              <h2 className="text-2xl">Email</h2>
              <p className="mb-4">General Inquiries<br/><a href="mailto:info@strongfoundationsct.com">info@strongfoundationsct.com</a></p>
            </div>
            <div className="sm:col-span-7">
              <ContactForm/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactUs