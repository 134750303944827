import * as React from "react"

const ContactForm = () => (
  <form
    name="contact"
    className="shadow m-4 p-5 bg-slate-50"
    method="post"
    data-netlify="true"
  >
    <input type="hidden" name="form-name" value="contact" />
    <fieldset>
      <legend className="mb-2 text-gray-900">Name*</legend>
      <div>
        <div className="flex flex-col mb-4">
          <label className="sr-only" htmlFor="fname">
            First name
          </label>
          <input
            className="border py-2 px-3 text-gray-900"
            type="text"
            id="fname"
            placeholder="First name"
            name="fname"
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="sr-only" htmlFor="lname">
            Last name
          </label>
          <input
            className="border py-2 px-3 text-gray-900"
            type="text"
            id="lname"
            placeholder="Last name"
            name="lname"
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <div className="flex flex-col mb-4">
        <label className="mb-2 text-gray-900" htmlFor="phone">
          Phone*
        </label>
        <input
          className="border py-2 px-3 text-gray-900"
          type="tel"
          id="phone"
          placeholder="Phone number"
          name="phone"
        />
      </div>
    </fieldset>
    
    <fieldset>
      <legend className="mb-2 text-gray-900">Services Desired</legend>
        <div className="mb-4">
          <label className="block">
            <input
              className="mx-3"
              type="checkbox"
              value="Yes"
              id="pre-k-and-k-aba"
              name="pre-k-and-k-aba"
            />
            Preschool / Kindergarten ABA
          </label>
          <label className="block">
            <input
              className="mx-3"
              type="checkbox"
              value="Yes"
              id="social-skills-club"
              name="social-skills-club"
            />
            Social Skills Club
          </label>
          <label className="block">
            <input
              className="mx-3"
              type="checkbox"
              value="Yes"
              id="summer-aba-sessions"
              name="summer-aba-sessions"
            />
            Summer ABA Sessions
          </label>
          <label className="block">
            <input
              className="mx-3"
              type="checkbox"
              value="Yes"
              id="occupational-therapy"
              name="occupational-therapy"
            />
            Occupational Therapy
          </label>
          <label className="block">
            <input
              className="mx-3"
              type="checkbox"
              value="Yes"
              id="counseling-and-diagnostics"
              name="counseling-and-diagnostics"
            />
            Counseling & Diagnostics
          </label>
        </div>
    </fieldset>

    <fieldset>
      <div className="flex flex-col mb-4">
        <label className="mb-2 text-gray-900" htmlFor="email">
          Email Address *
        </label>
        <input
          className="border py-2 px-3 text-gray-900"
          type="email"
          id="email"
          placeholder="Email Address"
          name="email"
        />
      </div>
    </fieldset>
    <fieldset>
      <div className="flex flex-col mb-4">
        <label className="mb-2 text-gray-900" htmlFor="subject">
          Subject *
        </label>
        <input
          className="border py-2 px-3 text-gray-900"
          type="text"
          id="subject"
          placeholder="Subject"
          name="subject"
        />
      </div>
    </fieldset>
    <div className="flex flex-col mb-4">
      <label className="mb-2 text-gray-900" htmlFor="messageTextArea">
        Message *
      </label>
      <textarea
        className="border py-2 px-3 text-gray-900"
        id="messageTextArea"
        rows="3"
        name="message"
      ></textarea>
    </div>
    <div>
      <button className="bg-purple-500 m-4 px-4 py-2 rounded-full text-white uppercase hover:text-gray-700" type="submit">
        Submit
      </button>
    </div>
  </form>
)

export default ContactForm
